import React, { useState, useEffect } from 'react';
import {
  DatePicker,
  Row,
  Col,
  Tooltip,
  Card,
  TreeSelect,
  Button,
  Collapse,
} from 'antd';
import { getUsersTypeApi } from '../../api/user';
import { getAccessTokenApi } from '../../api/auth';
import ListTurns from '../../components/Ambulatory/ListTurns';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import Spin from '../../components/Spin';
import locale from 'antd/lib/date-picker/locale/es_ES';
import 'dayjs/locale/es';
import { getTurnPortalApi } from '../../api/portal';
import { onPatientTurnsRead, onPrimaryDataRead } from '../../actions';
import { InfoCircleOutlined, WhatsAppOutlined } from '@ant-design/icons';
import './Turns.scss';
import TurnDetail from '../../components/TurnDetail/TurnDetail';
import LogoNombre from '../../assets/img/a1-8.png';
import { days, feriados } from '../../utils/constants';
import { getServicesApi } from '../../api/service';

export default function Turns(props) {
  const user = useSelector((store) => store.userData.user);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [slots, setSlots] = useState();
  const [date, setDate] = useState(dayjs().startOf('day').toISOString());
  const [turn, setTurn] = useState([]);
  const [turnUser, setTurnUser] = useState(null);
  const [reload, setReload] = useState(false);
  const [size, setSize] = useState();
  const [turnType, setTurnType] = useState(null);
  const [turnDetail, setTurnDetail] = useState(false);
  const [data, setData] = useState();
  const [configSchedule, setConfigSchedule] = useState();
  const [servicesData, setServicesData] = useState([]);

  dayjs.locale('es');

  const {
    tenant,
    organization,
    studies,
    organizationPhone,
    tenantId,
    portalActive,
    tenantLogo,
  } = useSelector((store) => store.tenantData);

  useEffect(() => {
    const getDataTurn = async () => {
      const token = await getAccessTokenApi();

      await getUsersTypeApi(tenantId).then(async (response) => {
        await setUsers(response.users);
      });

      turnType !== 'Medico' &&
        dispatch(onPrimaryDataRead(window.location.pathname.split('/')[1]));

      getServicesApi(tenantId)
        .then((response) => {
          setServicesData(response?.services);
        })
        .catch((err) => console.log(err));

      user?._id && dispatch(onPatientTurnsRead(user?._id));

      if (turnUser) {
        let dataSizeSlot =
          turnType !== 'medico'
            ? await studies.find((studie) => {
                if (studie.id === turnUser) return studie.defaultSizeSlot;
              })
            : await users.find((user) => {
                if (user._id === turnUser) return user;
              });
        await getTurnPortalApi(turnUser, date, tenantId).then(
          async (response) => {
            setTurn(response?.turns);
            generateSlots(
              response?.turns?.shifts.length > 0
                ? response?.turns.sizeSlot
                : dataSizeSlot?.defaultSizeSlot,
              response?.turns
            );
          }
        );

        setReload(false);
      }
    };

    getDataTurn();
  }, [date, turnUser, reload]);

  const generateSlots = async (sizeSlot, turns) => {
    let generate = [];
    let minutesOfHour = 60;

    let configSchedule = {
      excludeDays: [],
      lu: {},
      ma: {},
      mi: {},
      ju: {},
      vi: {},
      sá: {},
      do: {},
    };

    let data =
      turnType !== 'medico'
        ? await studies.find((studie) => {
            if (studie.id === turnUser) return studie;
          })
        : await users.find((user) => user._id === turnUser);

    setData(data);

    if (
      dayjs(date).diff(dayjs(), 'days') >
      (data?.scheduleConfig?.quantityDaysPortal || 15)
    ) {
      return setDate(dayjs().startOf('day').toISOString());
    }

    if (turns?.scheduleConfig && turns?.shifts?.length > 0) {
      await turns?.scheduleConfig?.hours.map((h, index) => {
        h.days.map((e) => {
          configSchedule[e] =
            Object.entries(configSchedule[e]).length === 0
              ? {
                  first: [
                    dayjs(turns.scheduleConfig?.hours[index]?.start),
                    dayjs(turns.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : Object.entries(configSchedule[e]).length === 1
              ? {
                  ...configSchedule[e],
                  second: [
                    dayjs(turns.scheduleConfig?.hours[index]?.start),
                    dayjs(turns.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : { ...configSchedule[e] };
        });
      });

      let configScheduleUpdating = {
        excludeDays: [],
        lu: {},
        ma: {},
        mi: {},
        ju: {},
        vi: {},
        sá: {},
        do: {},
      };

      await data.scheduleConfig?.hours.map((h, index) => {
        h.days.map((e) => {
          configScheduleUpdating[e] =
            Object.entries(configScheduleUpdating[e]).length === 0
              ? {
                  first: [
                    dayjs(data.scheduleConfig?.hours[index]?.start),
                    dayjs(data.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : Object.entries(configScheduleUpdating[e]).length === 1
              ? {
                  ...configScheduleUpdating[e],
                  second: [
                    dayjs(data.scheduleConfig?.hours[index]?.start),
                    dayjs(data.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : { ...configScheduleUpdating[e] };
        });
      });
      setConfigSchedule(configScheduleUpdating);
    } else {
      await data.scheduleConfig?.hours.map((h, index) => {
        h.days.map((e) => {
          configSchedule[e] =
            Object.entries(configSchedule[e]).length === 0
              ? {
                  first: [
                    dayjs(data.scheduleConfig?.hours[index]?.start),
                    dayjs(data.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : Object.entries(configSchedule[e]).length === 1
              ? {
                  ...configSchedule[e],
                  second: [
                    dayjs(data.scheduleConfig?.hours[index]?.start),
                    dayjs(data.scheduleConfig?.hours[index]?.end),
                  ],
                }
              : { ...configSchedule[e] };
        });
      });
      setConfigSchedule(configSchedule);
    }

    let hours =
      configSchedule[`${dayjs(date).format('dd')}`]?.first !== undefined &&
      configSchedule[`${dayjs(date).format('dd')}`]?.first[0].format('H');

    let minutes =
      configSchedule[`${dayjs(date).format('dd')}`]?.first !== undefined &&
      configSchedule[`${dayjs(date).format('dd')}`]?.first[0].format('mm');

    let hourStart = hours && dayjs(date).startOf('day').add(hours, 'h');

    hourStart = hourStart && hourStart.add(minutes, 'm');

    let res1 =
      hourStart &&
      configSchedule[`${hourStart.format('dd')}`]?.first !== undefined
        ? configSchedule[`${hourStart.format('dd')}`]?.first[1].diff(
            configSchedule[`${hourStart.format('dd')}`]?.first[0],
            'minute'
          ) / minutesOfHour
        : 0;

    let q1 = (minutesOfHour / sizeSlot) * res1;

    let res2 =
      hourStart &&
      configSchedule[`${hourStart.format('dd')}`]?.second !== undefined
        ? configSchedule[`${hourStart.format('dd')}`]?.second[1].diff(
            configSchedule[`${hourStart.format('dd')}`]?.second[0],
            'minute'
          ) / minutesOfHour
        : 0;

    let q2 = (minutesOfHour / sizeSlot) * res2 + (res2 !== 0 ? 1 : 0);

    if (
      !data.scheduleConfig?.excludeDays ||
      data.scheduleConfig?.excludeDays.filter(
        (e) =>
          dayjs(e).startOf('day').toISOString() ===
          dayjs(date).startOf('day').toISOString()
      ).length === 0
    ) {
      for (let i = 0; i < q1 + q2; i++) {
        generate[i] = {
          hourStart: hourStart.toISOString(),
        };
        if (i == Math.round(q1)) {
          if (
            configSchedule[`${hourStart.format('dd')}`]?.second !== undefined
          ) {
            let hours =
              configSchedule[`${dayjs(date).format('dd')}`]?.second[0].format(
                'H'
              );

            let minutes =
              configSchedule[`${dayjs(date).format('dd')}`]?.second[0].format(
                'mm'
              );

            hourStart = dayjs(date).startOf('day').add(hours, 'h');

            hourStart = hourStart.add(minutes, 'm');

            generate[i] = {
              hourStart: hourStart.toISOString(),
              exclude: true,
              second: true,
            };
            continue;
          }
        }

        hourStart = hourStart.add(sizeSlot, 'm');
      }
    } else {
      generate[0] = {
        exclude: true,
      };
    }

    setSlots(generate);
    setSize(sizeSlot);
  };

  if (turnUser && !slots) return <Spin />;

  return turnDetail ? (
    <TurnDetail
      turnDetail={turnDetail}
      doctor={
        turnType === 'medico'
          ? users.find((user) => turnUser === user._id)
          : studies.find((studie) => turnUser === studie.id)
      }
      turnType={turnType}
    />
  ) : (
    <div
      className={window.location.href.includes('portalturnos') ? 'online' : ''}
    >
      <Card
        title={`Turnos ${organization}`}
        extra={
          !window.location.href.includes('portalturnos') && (
            <Tooltip
              title="Solo puede solicitar un maximos de tres turnos. Toda la informacion en cuanto a reprogramación o
        cancelacion de sus turnos sera informada via email, por favor estar
        atento a todas las notificaciones enviadas, ante cualquier duda
        comunicarse con la intitución."
            >
              <InfoCircleOutlined />
            </Tooltip>
          )
        }
      >
        {portalActive && window.location.href.includes('portalturnos') && (
          <div className="online-studies">
            <a href={`https://portal${tenantId}.ampinasoft.com`}>
              Ver mis Estudios
            </a>
          </div>
        )}
        <Row gutter={[20, 24]} style={{ marginBottom: 5 }}>
          <Col sm={2} />

          <Col xs={24} sm={10}>
            <TreeSelect
              inputMode="none"
              style={{ width: '100%' }}
              placeholder="Busque por medico o especialidad"
              optionFilterProp="children"
              onChange={async (value, options) => {
                await setTurnType(value.includes('-') ? options[0] : 'medico');
                await setTurnUser(
                  value.includes('-') ? value.split('-')[0] : value
                );
              }}
              value={turnType === 'medico' ? turnUser : turnType}
              treeExpandAction="click"
              treeData={
                studies?.length > 0
                  ? [
                      {
                        value: 'med',
                        title: 'Staff Profesional',
                        selectable: false,
                        children: users?.map((user, index) => {
                          return {
                            value: user._id,
                            title: `${user.name} ${user.lastname} ${
                              user?.medicalSpeciality
                                ? `- ${user?.medicalSpeciality}`
                                : ''
                            }`,
                          };
                        }),
                      },
                      {
                        value: 'est',
                        title: 'Estudios',
                        selectable: false,
                        children: studies?.map((studie, index) => {
                          let services = servicesData?.filter((service) => {
                            if (
                              studie.scheduleConfig.service?.find(
                                (e) => e === service._id
                              )
                            ) {
                              return service;
                            }
                          });
                          return {
                            value: `${studie.id}-${index}`,
                            title: studie.name,
                            selectable: window.location.href.includes(
                              'portalturnos'
                            )
                              ? true
                              : false,
                            children:
                              !window.location.href.includes('portalturnos') &&
                              services?.map((service, i) => {
                                return {
                                  value: `${studie.id}-${i}`,
                                  title: service?.name,
                                };
                              }),
                          };
                        }),
                      },
                    ]
                  : [
                      {
                        value: 'med',
                        title: 'Staff Profesional',
                        selectable: false,
                        children: users?.map((user, index) => {
                          return {
                            value: user._id,
                            title: `${user.name} ${user.lastname} ${
                              user?.medicalSpeciality
                                ? `- ${user?.medicalSpeciality}`
                                : ''
                            }`,
                          };
                        }),
                      },
                    ]
              }
            />
          </Col>

          <Col xs={24} sm={10}>
            <DatePicker
              inputReadOnly
              disabled={!turnUser}
              cellRender={(current) => {
                const style = {};
                let feriado = feriados.filter(
                  (feriado) =>
                    feriado.mes == dayjs(current).format('M') &&
                    feriado.dia === current.date()
                );
                if (feriado.length === 1) {
                  style.border = '1px solid #c70000';
                  style.borderRadius = '50%';
                }
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }}
              style={{ width: '100%' }}
              format="DD-MM-YYYY"
              placeholder="Seleccione una fecha.. (*)"
              onChange={(value) => {
                setDate(dayjs(value).startOf('day').toISOString());
              }}
              disabledDate={(current) =>
                (current &&
                  (current < dayjs().startOf('day') ||
                    current.diff(dayjs(), 'days') >=
                      (data?.scheduleConfig?.quantityDaysPortal || 15))) ||
                feriados.filter(
                  (feriado) =>
                    feriado.mes == dayjs(current).format('M') &&
                    feriado.dia === current.date()
                ).length === 1 ||
                !configSchedule[`${current.format('dd')}`]?.first ||
                data.scheduleConfig?.excludeDays.filter(
                  (e) =>
                    dayjs(e).startOf('day').toISOString() ===
                    dayjs(current).startOf('day').toISOString()
                ).length === 1
              }
              locale={locale}
              allowClear={false}
              value={dayjs(date)}
            />
          </Col>
          <Col sm={2} />
        </Row>
        {turnUser ? (
          <div>
            <Collapse
              style={{ backgroundColor: '#E9E9E9' }}
              className="online-collapse"
            >
              <Collapse.Panel
                header="Horarios de atención habituales"
                collapsible="header"
              >
                <div className="online-hours">
                  {days.map(
                    (day) =>
                      configSchedule[`${day.id}`]?.first && (
                        <div className="online-hours-box">
                          <span>{day.name}</span>
                          <ul>
                            <li>
                              {configSchedule[`${day.id}`]?.first[0].format(
                                'HH mm'
                              )}
                              hs a{' '}
                              {configSchedule[`${day.id}`]?.first[1].format(
                                'HH mm'
                              )}
                              hs
                            </li>{' '}
                            {configSchedule[`${day.id}`]?.second && (
                              <li>
                                {configSchedule[`${day.id}`]?.second[0].format(
                                  'HH mm'
                                )}
                                hs a{' '}
                                {configSchedule[`${day.id}`]?.second[1].format(
                                  'HH mm'
                                )}
                                hs
                              </li>
                            )}
                          </ul>
                        </div>
                      )
                  )}
                </div>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '8pt',
                    color: 'grey',
                  }}
                >
                  Si ve turnos fuera de los horarios habituales, puede
                  solicitarlos. Se han habilitado turnos extra.
                </p>
              </Collapse.Panel>
            </Collapse>

            <ListTurns
              slots={slots}
              turnId={turn?._id}
              turns={turn?.shifts}
              turnUser={turnUser}
              setReload={setReload}
              size={size}
              turnType={turnType}
              date={date}
              setTurnDetail={setTurnDetail}
              scheduleConfig={data?.scheduleConfig}
            />
          </div>
        ) : (
          <>
            <div className="online-logo">
              <img
                src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/${tenant}/${
                  tenantLogo || 'logo'
                }.png`}
                alt="Logo"
              />
            </div>

            <div className="online-mini-logo">
              <p style={{ fontSize: '9pt' }}>
                En esta pagina solo aparecen los medicos y estudios que tienen
                su turnera web habilitada.
              </p>
              <p style={{ fontSize: '9pt' }}>
                Ante cualquier duda comunicarse con la institución o medico.
                Contacto:{' '}
                <Button type="link">
                  <a
                    href={`https://wa.me/${organizationPhone}?text=Hola%20${organization}`}
                    target="_blank"
                    referrerPolicy="origin"
                  >
                    <WhatsAppOutlined /> {organizationPhone}
                  </a>
                </Button>
              </p>
              <h2>Desarrollado por</h2>
              <a href="https://ampinasoft.com">
                <img src={LogoNombre} /> <h5>Visita Nuestra Web</h5>
              </a>
            </div>
          </>
        )}
      </Card>
    </div>
  );
}
